<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar
          @search="onSearch"
          searchLabel="Search by username or email..."
        />
      </v-col>
      <v-col class="grow text-right">
        <UserFilter
          ref="filters"
          :initialFilters="filters"
          @applyFilters="filters = JSON.parse(JSON.stringify($event))"
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="msaBlue"
              class="white--text"
              @click="exportItems()"
              v-on="on"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Export</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text"> Users </v-card-title>
          <v-data-table
            v-if="$vuetify.breakpoint.mdAndUp"
            :footer-props="{ ...$constants.PAGINATION.DEFAULT_FOOTER_PROPS }"
            :headers="headers"
            :items-per-page.sync="perPage"
            :items="users"
            :options.sync="options"
            :page.sync="page"
            :server-items-length="usersCount"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td
                  style="cursor: pointer"
                  :class="item.status != 0 ? 'msaBlue--text' : 'red--text'"
                  @click="item.status != 0 ? selectUser(item) : ''"
                >
                  {{ item.firstName }} {{ item.lastName }}
                  <br />
                  <span class="grey--text text-caption">{{
                    item.username
                  }}</span>
                </td>
                <td :class="item.status != 0 ? 'msaBlue--text' : 'red--text'">
                  {{ item.email }}
                </td>
                <td
                  style="cursor: pointer"
                  class="msaBlue--text"
                  @click="goToCompanyDetail(item)"
                >
                  {{ item.companyName }}
                </td>
                <td align="center" class="account-type">
                  <div :class="getAccountTypeClass(item)">
                    <span class="white--text">{{ item.accountType }}</span>
                  </div>
                </td>
                <td class="msaBlue--text checkboxes" style="text-align: center">
                  <v-icon :color="item.status != 0 ? 'green' : 'red'">
                    {{ item.status != 0 ? 'mdi-check' : '' }}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      v-if="$vuetify.breakpoint.smAndDown"
      :items="users"
      hide-default-header
      :server-items-length="usersCount"
      :footer-props="{ ...$constants.PAGINATION.DEFAULT_FOOTER_PROPS }"
      :options.sync="options"
      :items-per-page.sync="perPage"
      :page.sync="page"
    >
      <template v-slot:item="{ item }">
        <v-card class="my-1" :color="item.status != 0 ? 'msaBlue' : 'red'">
          <v-card-title>
            <v-row>
              <v-col
                style="cursor: pointer"
                class="white--text pb-0"
                cols="6"
                @click="selectUser(item)"
              >
                {{ item.firstName }} {{ item.lastName }}
              </v-col>
              <v-col
                style="cursor: pointer"
                cols="6"
                class="white--text pb-0 text-body-1"
                align="right"
                @click="goToCompanyDetail(item)"
              >
                {{ item.companyName }}
              </v-col>
              <v-col class="white--text text-caption">
                {{ item.username }}
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import UserFilter from '@/components/Filters/UserFilter.vue';
export default {
  name: 'UserList',
  components: {
    SearchBar,
    UserFilter,
  },
  watch: {
    options() {
      this.getUsers();
    },
    filters: {
      deep: true,
      handler() {
        this.options.page = 1;
        this.getUsers();
      },
    },
  },
  data() {
    return {
      selectedUser: undefined,
      reload: false,
      search: '',
      users: [],
      usersCount: 0,
      page: this.$constants.PAGINATION.DEFAULT_PAGE,
      perPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      options: {},
      filters: JSON.parse(
        JSON.stringify(this.$constants.USER_LIST_DEFAULT_FILTERS),
      ),
      companyId: undefined,
      headers: [
        {
          text: 'Name',
          value: 'fullname',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Email',
          value: 'email',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Company',
          value: 'companyName',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Account Type',
          value: 'accountType',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '175px',
        },
        {
          text: 'Active',
          value: 'status',
          align: 'center',
          class: 'lightGrey',
          width: '85px',
        },
      ],
    };
  },
  methods: {
    // Returns CSS class for styling the Account Type chips
    getAccountTypeClass(company) {
      switch (company.accountType.toLowerCase()) {
        case 'basic':
          return 'subscription';
        case 'a la carte':
          return 'aLaCarte';
        case 'free trial':
          return 'freetrial';
        case 'freemium':
          return 'freemium';
        case 'basic (old)':
          return 'basicOld';
        case 'peace of mind':
          return 'peaceOfMind';
        case 'freedom to operate':
          return 'freedomToOperate';
        case 'all access':
          return 'allAccess';
        case 'basic (new)':
          return 'basicNew';
        case 'test account':
          return 'testAccount';
        case 'training':
          return 'training';
        case 'training plus':
          return 'trainingPlus';
        default:
          return '';
      }
    },
    getUsers() {
      const params = {
        search: this.search ?? '',
        filters: this.filters,
        options: this.options,
        loaderText: 'Loading...',
      };
      this.users = [];
      this.$axios
        .post('get-users?format=json', params)
        .then((response) => {
          this.users = response.data.items;
          this.usersCount = response.data.count;
        })
        .catch((error) => error);
    },
    selectUser(user) {
      this.$store.commit('updateSelectedCompany', {
        id: user.companyId,
        name: user.companyName,
        status: user.companyStatus,
        companyGroupId: user.companyGroupId,
      });
      this.$router.push({
        name: 'IAUserDetails',
        params: { id: user.id, companyId: user.companyId },
      });
    },
    goToCompanyDetail(user) {
      this.$store.commit('updateSelectedCompany', {
        id: user.companyId,
        name: user.companyName,
        status: user.companyStatus,
        companyGroupId: user.companyGroupId,
      });
      this.$router.push({
        name: 'CompanyDetail',
        params: { companyId: user.companyId },
      });
    },
    exportItems() {
      let params = {
        search: this.search ?? '',
        filters: this.filters,
        options: this.options,
        loaderText: 'Loading...',
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      this.$axios
        .post('export-users?format=json', params, options)
        .then((response) => {
          this.$helpers.downloadFile(response.data, 'users export.xlsx');
        });
    },
    onSearch(searchText) {
      if (this.search != searchText) {
        this.search = searchText;
        this.options.page = 1;
        this.getUsers();
      }
    },
  },
};
</script>
<style>
.account-type div {
  border-radius: 5px;
}
.msaBlue--text.checkboxes .v-input__slot {
  justify-content: center !important;
}
</style>
