<template>
  <v-container class="pa-0 mx-0" fluid>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :class="(filtersApplied ? 'orange' : 'msaBlue') + ' white--text'"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              v-blur
              @click="resetFilters"
            >
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title class="headline msaBlue white--text">
          <v-row>
            <v-col class="grow"> Filters </v-col>
            <v-col class="shrink">
              <v-btn icon dark @click="dialog = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-6">
          <v-row class="text-body-1">GENERAL</v-row>
          <v-row>
            <v-divider></v-divider>
          </v-row>
          <v-row align="center">
            <v-col cols="4" align="right"> User status </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.status"
                mandatory
                dense
                active-class="msaBlue white--text"
                :style="{ width: '100%' }"
              >
                <v-btn
                  :value="1"
                  width="33%"
                  data-testid="status-active-button"
                >
                  Active
                </v-btn>
                <v-btn
                  :value="0"
                  width="33%"
                  data-testid="status-inactive-button"
                >
                  Inactive
                </v-btn>
                <v-btn :value="2" width="33%" data-testid="status-all-button">
                  All
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" align="right"> Web Administrator </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.isWebAdmin"
                mandatory
                dense
                active-class="msaBlue white--text"
                :style="{ width: '100%' }"
              >
                <v-btn
                  :value="1"
                  width="33%"
                  data-testid="status-active-button"
                >
                  Yes
                </v-btn>
                <v-btn
                  :value="0"
                  width="33%"
                  data-testid="status-inactive-button"
                >
                  No
                </v-btn>
                <v-btn :value="2" width="33%" data-testid="status-all-button">
                  All
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" align="right"> Supervisor </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.isSupervisor"
                mandatory
                dense
                active-class="msaBlue white--text"
                :style="{ width: '100%' }"
              >
                <v-btn
                  :value="1"
                  width="33%"
                  data-testid="status-active-button"
                >
                  Yes
                </v-btn>
                <v-btn
                  :value="0"
                  width="33%"
                  data-testid="status-inactive-button"
                >
                  No
                </v-btn>
                <v-btn :value="2" width="33%" data-testid="status-all-button">
                  All
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" align="right"> Manager </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.isManager"
                mandatory
                dense
                active-class="msaBlue white--text"
                :style="{ width: '100%' }"
              >
                <v-btn
                  :value="1"
                  width="33%"
                  data-testid="status-active-button"
                >
                  Yes
                </v-btn>
                <v-btn
                  :value="0"
                  width="33%"
                  data-testid="status-inactive-button"
                >
                  No
                </v-btn>
                <v-btn :value="2" width="33%" data-testid="status-all-button">
                  All
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" align="right"> Safety </v-col>
            <v-col>
              <v-btn-toggle
                v-model="filters.isSafetyRep"
                :style="{ width: '100%' }"
                active-class="msaBlue white--text"
                dense
                mandatory
              >
                <v-btn
                  :value="1"
                  width="33%"
                  data-testid="status-active-button"
                >
                  Yes
                </v-btn>
                <v-btn
                  :value="0"
                  width="33%"
                  data-testid="status-inactive-button"
                >
                  No
                </v-btn>
                <v-btn :value="2" width="33%" data-testid="status-all-button">
                  All
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" align="right">Account Type</v-col>
            <v-col>
              <CustomizedAutoComplete
                :items="[...this.labels]"
                :value="filters.accountTypes"
                clearable
                deletable-chips
                hide-details
                label="Select account type(s)"
                multiple
                @change="updateAccountTypes"
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4" align="right" class="pt-0 mt-n4">
              Phone Number
            </v-col>
            <v-col>
              <v-text-field
                v-model="filters.phone"
                dense
                outlined
                :rules="rules"
                data-testid="phone"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            class="mr-5"
            @click="
              filters = JSON.parse(
                JSON.stringify($constants.USER_LIST_DEFAULT_FILTERS),
              );
              applyFilters();
            "
          >
            Clear
          </v-btn>
          <v-btn
            color="msaBlue white--text"
            @click="applyFilters()"
            :disabled="shouldDisableApply"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: 'UserFilter',
  props: {
    initialFilters: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      filters: {},
      labels: [
        { id: 1, name: 'Basic' },
        { id: 2, name: 'A La Carte' },
        { id: 3, name: 'Free Trial' },
        { id: 4, name: 'Freemium' },
        { id: 6, name: 'Peace of Mind' },
        { id: 7, name: 'Freedom to Operate' },
        { id: 8, name: 'All Access' },
        { id: 10, name: 'Training' },
        { id: 11, name: 'Training Plus' },
      ],
      rules: [(v) => this.$helpers.validatePhone(v)],
    };
  },
  computed: {
    filtersApplied() {
      return (
        JSON.stringify(this.initialFilters) !=
        JSON.stringify(this.$constants.USER_LIST_DEFAULT_FILTERS)
      );
    },
    shouldDisableApply() {
      return this.$helpers.validatePhone(this.filters.phone) != true;
    },
  },
  methods: {
    applyFilters() {
      this.$emit('applyFilters', this.filters);
      this.dialog = false;
    },
    clearFilters() {
      this.resetFilters();
      this.applyFilters();
    },
    // Clears the filter fields that have not been applied
    resetFilters() {
      this.filters = JSON.parse(JSON.stringify(this.initialFilters));
    },
    updateAccountTypes(event) {
      this.filters.accountTypes = event;
    },
  },
};
</script>
<style></style>
